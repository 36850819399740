import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import PricingTable from "../../../../src/content/blog-posts/doggos-vs-rover-vs-wag/PricingTable.js";
import PlaceholderImage from "../../../../src/content/blog-posts/doggos-vs-rover-vs-wag/image1.png";
import Slider4 from "../../../../src/content/blog-posts/doggos-vs-rover-vs-wag/slider4.jpg";
import * as style from "../../../../src/content/blog-posts/doggos-vs-rover-vs-wag/style.module.scss";
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  PricingTable,
  PlaceholderImage,
  Slider4,
  style,
  React
};