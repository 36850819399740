import * as React from 'react'
import { Popover as ReakitPopover, PopoverArrow } from 'reakit/Popover'
import * as style from './style.module.scss'

const Popover = (props) => {
  const { children, ...popover } = props

  return (
    <ReakitPopover className={style.popover} {...popover}>
      <PopoverArrow {...popover} />
      {children}
    </ReakitPopover>
  )
}

export default Popover
